import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UilArrowUpRight } from '@iconscout/react-unicons';
import { desktopViews, mobileViews, tabletViews } from '../../utils/media';
import { bottomIndex } from '../../utils/common';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    z-index: ${bottomIndex};
    padding: 0.6rem 2rem;
    background: var(--neutral-100);

    &.homepage {
        background: var(--neutral-100);
    }

    &.product {
        background: var(--secondary-50);
    }

    &::before {
        content: '';
        background: var(--neutral-400);
        opacity: 0.4;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        padding: 1.4rem 2.2rem;
    }
`;

const Inner = styled.div`
    display: grid;
    grid-template-areas: 'copyright link';
    align-items: center;
    justify-content: space-between;
    gap: 1.2rem 3rem;

    /* max-width: 1040px */
    @media ${desktopViews.xxs} {
        grid-template-areas:
            'link'
            'copyright';
    }

    /* max-width: 480px */
    @media ${mobileViews.xlg} {
        column-gap: 2rem;
    }
`;

const CopyrightTxt = styled.p`
    grid-area: copyright;
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 1.4rem;
    color: var(--txt-inactive);

    &.lang-pl {
        font-family: 'Noto Sans Condensed', 'Roboto', sans-serif;
        letter-spacing: 1px;
    }

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', 'open-sans';
    }
`;

const LinksWrapper = styled.div`
    grid-area: link;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 2rem;
`;

const Link = styled.a`
    font-family: 'Assistant', 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-size: 1.5rem;
    color: var(--txt-inactive);
    font-weight: 500;

    &.lang-pl {
        font-family: 'Noto Sans Condensed', 'Roboto', sans-serif;
        letter-spacing: 1px;
    }

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', 'open-sans';
    }

    .icon {
        width: 1.3rem;
    }

    &:hover {
        color: var(--txt-active);
    }
`;

const Line = styled.div`
    color: var(--txt-inactive);
    /* color: rgba(255, 255, 255, 0.6); */

    /* max-width: 414px */
    @media (max-width: 414px) {
        display: none;
    }
`;

interface Props {
    curLang: string;
    curView?: string | null;
    isHomePage?: boolean;
}

const Footer: React.FC<Props> = ({ curView, curLang }) => {
    const { t } = useTranslation();
    const curDate = new Date();
    const curYear = curDate.getFullYear();
    return (
        <Wrapper className={curView === 'product' ? 'product' : 'homepage'}>
            <Inner>
                <LinksWrapper>
                    <Link className={`lang-${curLang}`} href={`https://blubina.de/${curLang}`} target="_blank" rel="noopner noreferrer">
                        Blubina
                        <UilArrowUpRight className="icon icon-link" />
                    </Link>
                    <Line>|</Line>
                    <Link className={`lang-${curLang}`} href={`https://blubina.de/${curLang}/privacy-policy`} target="_blank" rel="noopner noreferrer">
                        Blubina {t('uPointDMS.privacyPolicy.main.title')}
                        <UilArrowUpRight className="icon icon-link" />
                    </Link>
                    <Line>|</Line>
                    <Link className={`lang-${curLang}`} href={`/${curLang}/privacy-policy`} target="_blank" rel="noopner noreferrer">
                        uPoint DMS {t('uPointDMS.privacyPolicy.main.title')}
                        <UilArrowUpRight className="icon icon-link" />
                    </Link>
                    <Link className={`lang-${curLang}`} href={`/${curLang}/terms-conditions`} target="_blank" rel="noopner noreferrer">
                        uPoint DMS {t('uPointDMS.terms.main.title')}
                        <UilArrowUpRight className="icon icon-link" />
                    </Link>
                </LinksWrapper>
                <CopyrightTxt className={`lang-${curLang}`}>© {curYear}, Blubina GmbH Munich. Names and logos of Outlook, SharePoint, Windows and others are registered trademarks of Microsoft.</CopyrightTxt>
            </Inner>
        </Wrapper>
    );
};

export default Footer;
