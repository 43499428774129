import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UilArrowUpRight } from '@iconscout/react-unicons';
import { StyledWrapperStandard, StyledInner, Img } from '../../components/common.style';
import { tabletViews } from '../../utils/media';
import Button from '../../components/buttons/Button';
import { Text1 } from '../../components/common.styles.font';

const Wrapper = styled(StyledWrapperStandard)``;

const ContentWrapper = styled(StyledInner)`
    position: relative;
    display: grid;
    grid-template-columns:
        minmax(38rem, 5fr)
        minmax(6rem, 1fr) minmax(26rem, 40rem);
    grid-template-areas: 'img .  text';
    align-items: center;

    button {
        max-width: fit-content;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        grid-template-columns: 1fr;
        grid-template-areas:
            'img'
            'text';
        row-gap: 4rem;
    }
`;

const TextBtnWrapper = styled.div`
    grid-area: text;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        gap: 3.2rem;
    }
`;

const ImgWrapper = styled.div`
    grid-area: img;
    width: 100%;
    height: 100%;

    img {
        cursor: pointer;
    }
`;

interface Props {
    curLang: string;
    dataName: string;
    section: { desc: string; img: any; btnAction: () => void };
}

const ProductMoreVideoSection: React.FC<Props> = ({ curLang, dataName, section }) => {
    const { t } = useTranslation();

    return (
        <Wrapper id={`product-${dataName}_video-section`} className="bg-secondary">
            <ContentWrapper>
                <ImgWrapper onClick={section.btnAction}>
                    <Img
                        id={`product-${dataName}__img-more-vids`}
                        src={section.img}
                        alt="product-more-videos"
                        onClick={() => null}
                    />
                </ImgWrapper>

                <TextBtnWrapper>
                    <Text1 className={`lang-${curLang}`}>{t(section.desc)}</Text1>

                    <Button
                        curLang={curLang}
                        fs="17"
                        appearance="primary-outline"
                        width="fit-content"
                        txt={t('common.btn.viewMoreVideos')}
                        icon={<UilArrowUpRight className="icon icon-arrow-up" />}
                        handleClick={section.btnAction}
                    />
                </TextBtnWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default ProductMoreVideoSection;
